.pagefule-list--image {
  max-width: 100%;
  max-height: 100%;
}

.pagefule-header--margin-2 {
  margin-top: 2em;
}

.pagefule-list-item {
  margin-bottom: 2em;
}

.pagefule-card-link {
  text-decoration: none;
  color: #000;
}

.pagefule-card-link:hover {
  text-decoration: none;
  background-color: red;
}

a:hover {
  text-decoration: none;
  color: #000;
}