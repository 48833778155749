.App {
  font-family: 'Playfair Display', serif;
}

.pagefule-navigation {
  border-bottom: 1px solid #e9ecef;
}

.pagefule-button--rounded {
  border-radius: 25px;
  margin: 0.5em;
}