.pagefule-home {
  &--image {
    margin-top: 6em;
    max-width: 100%;
    max-height: 100%;
  }

  &--footer {
    margin-top: 10em;
  }

  &--header {
    margin-bottom: 1em;
  }

  &--paragraph {
    margin-bottom: 2em;
  }

  &--pagefule-home--buttons {
    display: inline-block;
  }
}

.pagefule-home--footer a:link {
  color: #000;
}

.pagefule-header--margin {
  margin-top: 5em;
}